// fix for 100vh issue on mobile devices
// references:
//   https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
//   https://dev.to/admitkard/mobile-issue-with-100vh-height-100-100vh-3-solutions-3nae

const setAppViewportHeight = () => {
  // const height = document.documentElement.clientHeight
  const height = window.innerHeight

  document
    .querySelector(':root')
    .style
    .setProperty('--app-vh', height/100 + 'px')
}

const updateAppViewportHeight = () => {
  window.requestAnimationFrame(setAppViewportHeight);
}

window.addEventListener('resize', updateAppViewportHeight)
updateAppViewportHeight()
